import { Box, Text, Spinner } from "@chakra-ui/react";
import { Field } from "formik";
import React, { ChangeEvent, useCallback, useState } from "react";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { getPostcodeAddresses } from "api/lead-pro.api";
import { INPUT_DEFAULT_STYLE } from "constants/inputStyles";
import { postcodeRegex } from "utils/regexes";
import { DelayedInput } from "components/FormControl/inputs/DelayedInput";
import { PostcodeAddressType } from "types/postcode-address.type";
import { FormikProps } from "formik/dist/types";
import { PropertyDetailsFormData } from "types/property-valuation-form-data.type";

interface IAddressFinderProps {
  appId: string;
  form: FormikProps<PropertyDetailsFormData>;
}

export const AddressFinder = ({ appId, form }: IAddressFinderProps) => {
  const [addresses, setAddresses] = useState<PostcodeAddressType[]>([]);
  const [loading, setLoading] = useState(false);

  const { values, setFieldValue, setFieldError } = form;
  const { postcode, address_line_1 } = values;

  const handleFindAddress = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;

      if (postcodeRegex.test(searchValue)) {
        setLoading(true);
        try {
          const response = await getPostcodeAddresses(appId, searchValue);
          setAddresses(response);
          setFieldValue("postcode", searchValue);
        } catch (e) {
          setFieldError(
            "postcode",
            "No addresses were found for this postcode."
          );
        }

        setLoading(false);
      } else {
        setFieldError("postcode", "Please enter a valid postcode.");
      }
    },
    [setLoading, setAddresses, setFieldValue, setFieldError, appId]
  );

  const resetAddresses = useCallback(async () => {
    setFieldValue("postcode", "");
    setFieldValue("address_line_1", "");
    setFieldValue("address_line_2", "");
    setFieldValue("address_line_3", "");
    setAddresses([]);
  }, [setFieldValue, setAddresses]);

  return (
    <Field name="postcode" id="postcode">
      {({ form }: { form: FormikProps<PropertyDetailsFormData> }) => {
        if (!!address_line_1) {
          return (
            <Box
              lineHeight="none"
              border="1px solid"
              borderColor="gray.200"
              p={4}
              rounded="sm"
            >
              <Box fontWeight="semibold" mb={1}>
                {address_line_1}
              </Box>
              <Box
                fontSize="sm"
                color="gray.500"
                mb={2}
                textTransform="uppercase"
              >
                {postcode}
              </Box>
              <Box
                onClick={resetAddresses}
                fontSize="sm"
                textDecoration="underline"
                cursor="pointer"
              >
                Select a different address
              </Box>
            </Box>
          );
        }

        return (
          <>
            <Box position={"relative"}>
              <DelayedInput
                autoFocus={true}
                placeholder="Enter your postcode"
                onChange={handleFindAddress}
                {...INPUT_DEFAULT_STYLE}
              />
              {loading && (
                <Box position={"absolute"} right={"4px"} top={"7px"}>
                  <Spinner />
                </Box>
              )}
            </Box>
            {addresses.length > 0 ? (
              <Box
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                mt={1}
                maxHeight="300px"
                overflowY="scroll"
                rounded="md"
              >
                {addresses.map((address, index) => {
                  const { postcode, line_1, line_2, line_3 } = address;

                  return (
                    <Box
                      key={index}
                      cursor="pointer"
                      borderBottom="1px solid"
                      borderColor="gray.200"
                      fontSize="base"
                      whiteSpace="nowrap"
                      p={3}
                      bg={
                        address_line_1 === line_1 ? "green.100" : "transparent"
                      }
                      onClick={() => {
                        setFieldValue("postcode", postcode);
                        setFieldValue("address_line_1", line_1);
                        setFieldValue("address_line_2", line_2);
                        setFieldValue("address_line_3", line_3);
                      }}
                      _hover={{
                        bg: address_line_1 === line_1 ? "green.50" : "gray.100"
                      }}
                    >
                      <Text lineHeight="normal" mb={1}>
                        {line_1}
                      </Text>
                      <Text lineHeight="normal" color="gray.500" fontSize="sm">
                        {line_2} {line_3}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
            {form.errors["postcode"] ||
            (form.errors["address_line_1"] &&
              form.touched["address_line_1"]) ? (
              <ErrorMessage>
                {form.errors["postcode"] || form.errors["address_line_1"]}
              </ErrorMessage>
            ) : null}
          </>
        );
      }}
    </Field>
  );
};
