import { Box } from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import React, { FC } from "react";
import { AddressFinder } from "./components/AddressFinder";
import * as yup from "yup";
import { BEDROOM_OPTIONS } from "constants/bedroomOptions";
import { ETA_OPTIONS } from "constants/etaOptions";
import { LEAD_KIND_OPTIONS } from "constants/leadKindOptions";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { BrandedButton } from "components/BrandedButton/BrandedButton";
import { FormControl } from "components/FormControl/FormControl";
import { InputEnum } from "enums/input.enum";
import { FieldProps } from "formik/dist/Field";
import { PropertyValuationFormDataType } from "types/property-valuation-form-data.type";
import { FormikHelpers } from "formik/dist/types";
import { PROPERTY_TYPE_OPTIONS } from "../../../../constants/propertyTypeOptions";
import { BATHROOM_OPTIONS } from "../../../../constants/bathroomOptions";
import { RECEPTION_OPTIONS } from "../../../../constants/receptionOptions";

interface IPropertyDetailsFormProps {
  values: Partial<PropertyValuationFormDataType>;
  handleSubmit: (
    newValues: Partial<PropertyValuationFormDataType>,
    actions: FormikHelpers<Partial<PropertyValuationFormDataType>>
  ) => void;
}

export const PropertyDetailsForm: FC<IPropertyDetailsFormProps> = ({
  values,
  handleSubmit
}) => {
  const {
    config: {
      appId,
      pageIvtConfig: {
        presetValuationType,
        etaEnabled,
        showBathroomsAndReceptions
      }
    }
  } = useAppContext();

  const initialValues = {
    postcode: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    propertyType: undefined,
    bedrooms: undefined,
    eta: undefined,
    type: presetValuationType || undefined,
    bathrooms: undefined,
    receptions: undefined
  };

  const validationSchema = yup.object().shape({
    postcode: yup
      .string()
      .required("Please add a postcode.")
      .min(5, "Please enter a valid postcode."),
    address_line_1: yup.string().required("Please select an address."),
    address_line_2: yup.string(),
    address_line_3: yup.string(),
    propertyType: yup.string().required("Please select a property type."),
    bedrooms: yup
      .number()
      .nullable()
      .required("Please add number of bedrooms."),
    eta: etaEnabled
      ? yup
          .string()
          .nullable()
          .required("Please specify ETA.")
      : yup.string().nullable(),
    type: yup
      .string()
      .nullable()
      .required("Please specify a reason."),
    bathrooms: showBathroomsAndReceptions
      ? yup
          .number()
          .nullable()
          .required("Please specify number of bathrooms.")
      : yup.number().nullable(),
    receptions: showBathroomsAndReceptions
      ? yup
          .number()
          .nullable()
          .required("Please specify number of reception rooms.")
      : yup.number().nullable()
  });

  return (
    <Formik<Partial<PropertyValuationFormDataType>>
      initialValues={{ ...initialValues, ...values }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box mb={3}>
            <Field name="postcode" id="postcode">
              {({ form }: FieldProps) => (
                <AddressFinder appId={appId} form={form} />
              )}
            </Field>
          </Box>
          <Box mb={3}>
            <FormControl
              name={"propertyType"}
              type={InputEnum.SELECT}
              inputProps={{
                placeholder: "Property type",
                options: PROPERTY_TYPE_OPTIONS,
                isClearable: true
              }}
            />
          </Box>
          <Box mb={3}>
            <FormControl
              name={"bedrooms"}
              type={InputEnum.SELECT}
              inputProps={{
                placeholder: "Number of bedrooms",
                options: BEDROOM_OPTIONS,
                isClearable: true
              }}
            />
          </Box>

          {showBathroomsAndReceptions && (
            <>
              <Box mb={3}>
                <FormControl
                  name={"receptions"}
                  type={InputEnum.SELECT}
                  inputProps={{
                    placeholder: "Number of reception rooms.",
                    options: RECEPTION_OPTIONS,
                    isClearable: true
                  }}
                />
              </Box>
              <Box mb={3}>
                <FormControl
                  name={"bathrooms"}
                  type={InputEnum.SELECT}
                  inputProps={{
                    placeholder: "Number of bathrooms.",
                    options: BATHROOM_OPTIONS,
                    isClearable: true
                  }}
                />
              </Box>
            </>
          )}

          {etaEnabled && (
            <Box mb={3}>
              <FormControl
                name={"eta"}
                type={InputEnum.SELECT}
                inputProps={{
                  placeholder: "When do you plan to move?",
                  options: ETA_OPTIONS,
                  isClearable: true
                }}
              />
            </Box>
          )}

          {!presetValuationType && (
            <Box mb={3}>
              <FormControl
                name={"type"}
                type={InputEnum.SELECT}
                inputProps={{
                  placeholder: "Why do you need this valuation?",
                  options: LEAD_KIND_OPTIONS,
                  isClearable: true
                }}
              />
            </Box>
          )}
          <BrandedButton
            type={"submit"}
            width={"100%"}
            colorScheme={"primary"}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </BrandedButton>
        </Form>
      )}
    </Formik>
  );
};
