import { FullAddressType } from "types/full-address.type";

const generateAddress = (fullAddress: Partial<FullAddressType>) => {
  const {
    address_line_1,
    address_line_2,
    address_line_3,
    postcode
  } = fullAddress;
  const components = [];
  if (address_line_1) {
    components.push(address_line_1);
  }
  if (address_line_2) {
    components.push(address_line_2);
  }
  if (address_line_3) {
    components.push(address_line_3);
  }
  if (postcode) {
    components.push(postcode.toUpperCase());
  }
  if (components.length) {
    return components.join(", ");
  }
  return "";
};

export default generateAddress;
