import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import _ from "underscore";
import { PropertyDataType } from "types/property-data.type";
import { ValuationReportComparableCardImageThumb } from "./ValuationReportComparableCardImageThumb";

interface IValuationReportComparableCardImageThumbsProps {
  property: PropertyDataType;
  handleSetActivePhoto: (index: number) => void;
  activePhoto: number;
}

export const ValuationReportComparableCardImageThumbs: FC<IValuationReportComparableCardImageThumbsProps> = ({
  property,
  handleSetActivePhoto,
  activePhoto
}) => {
  const { get_agent_num_photos, get_agent_id } = property;

  return (
    <Box display="flex" p={2} position="absolute" bottom={0} width="100%">
      {_.times(get_agent_num_photos, i => {
        return (
          i < 4 && (
            <ValuationReportComparableCardImageThumb
              key={i}
              index={i}
              id={get_agent_id}
              handleSetActivePhoto={handleSetActivePhoto}
              activePhoto={activePhoto}
            />
          )
        );
      })}
    </Box>
  );
};
