import axios from "axios";

import { PostcodeAddressType } from "types/postcode-address.type";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

export const getPostcodeAddresses = async (
  appId: string,
  postcode: string
): Promise<PostcodeAddressType[]> => {
  const { data } = await axios.get(
    `${env.REACT_APP_NEW_API_BASE_URL}/ivt/addresses?postcode=${postcode}`,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  return data.data;
};
