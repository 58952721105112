import {
  FormFieldRadio,
  FormFieldType,
  FormSchema,
  getReachableFieldsInOrder,
  validateFormData
} from "@leadpro/forms";
import { FormikErrors } from "formik/dist/types";
import { IFormControlProps } from "../components/FormControl/FormControl";
import { InputEnum } from "../enums/input.enum";
import { isValidNumberForRegion } from "libphonenumber-js";
import { pick } from "underscore";
import { FormLabelProps as CFormLabelProps } from "@chakra-ui/form-control/dist/declarations/src/form-label";

type TValues = Record<string, any>;
type TSchemaItem = FormSchema["fields"][number];

const DEFAULT_VALUES_BY_INPUT_TYPE: { [key in FormFieldType]: unknown } = {
  [FormFieldType.TEXT]: "",
  [FormFieldType.EMAIL]: "",
  [FormFieldType.PHONE]: "",
  [FormFieldType.POSTCODE]: "",
  [FormFieldType.TEXT_AREA]: "",
  [FormFieldType.RADIO]: ""
};

const FORM_CONTROL_TYPE_BY_SCHEMA_ITEM_TYPE: {
  [key in FormFieldType]: InputEnum;
} = {
  [FormFieldType.TEXT]: InputEnum.TEXT,
  [FormFieldType.EMAIL]: InputEnum.TEXT,
  [FormFieldType.PHONE]: InputEnum.TEXT,
  [FormFieldType.POSTCODE]: InputEnum.TEXT,
  [FormFieldType.TEXT_AREA]: InputEnum.TEXT_AREA,
  [FormFieldType.RADIO]: InputEnum.SELECT
};

export type TFormFromSchemaValues = {
  [key: string]: unknown;
  gdprCheckbox: boolean | null;
};

export const getReachableData = (
  data: Partial<TFormFromSchemaValues>,
  formSchema: FormSchema
) => {
  const reachableFieldsInOrder = getReachableFieldsInOrder(formSchema, data);
  return pick(data, ...reachableFieldsInOrder);
};

export function getInitialFormFieldValuesFromSchema(
  schema: FormSchema,
  gdprCheckboxEnabled: boolean
): TFormFromSchemaValues {
  const values: TFormFromSchemaValues = {
    gdprCheckbox: gdprCheckboxEnabled ? false : null
  };
  schema.order.map(fieldKey => {
    const schemaItem = schema.fields[fieldKey];
    const type = schemaItem.type;
    values[fieldKey] = DEFAULT_VALUES_BY_INPUT_TYPE[type];
    return values;
  });

  return values;
}

export function validateFormFieldValuesFromSchema<Values extends TValues>(
  values: Values,
  schema: FormSchema
): FormikErrors<Values> | void {
  const truncatedValues = getTruncatedValues<Values>(values, schema);
  const { errors } = validateFormData(schema, truncatedValues, {
    phoneNumberValidator: isValidNumberForRegion
  });
  if (!errors) return;

  const result: Partial<FormikErrors<Values>> = {};
  errors.forEach(error => {
    const fieldKey = error.path[0] as keyof Values;
    result[fieldKey] = error.message as any;
  });

  return result;
}

export function getFormControlPropsFromSchemaItem(
  schema: FormSchema,
  fieldKey: string
): IFormControlProps {
  const schemaItem = schema.fields[fieldKey];
  const props = {
    name: fieldKey,
    label: schemaItem.label,
    isRequired: schemaItem.required,
    type: FORM_CONTROL_TYPE_BY_SCHEMA_ITEM_TYPE[schemaItem.type],
    labelProps: {
      textTransform: "none" as CFormLabelProps["textTransform"]
    }
  };

  appendInputTypeSpecificProps(schemaItem, props);
  return props;
}

function appendInputTypeSpecificProps(
  schemaItem: TSchemaItem,
  props: IFormControlProps
) {
  props.inputProps = {
    placeholder: schemaItem.placeholder
  };

  if (props.type === InputEnum.SELECT) {
    props.inputProps = {
      options: (schemaItem as FormFieldRadio).options,
      isMulti: (schemaItem as FormFieldRadio).multiple,
      isCreatable: (schemaItem as FormFieldRadio).otherOption
    };
  }
}

export function getTruncatedValues<Values extends TValues>(
  values: Values,
  schema: FormSchema
): Values {
  const schemaFieldKeys = Object.keys(schema.fields);
  const newValues: Values = { ...values };
  schemaFieldKeys.forEach((key: string) => {
    const schemaField = schema.fields[key];
    if (!schemaField.required && !values[key]) {
      delete newValues[key];
    }
  });

  return newValues as Values;
}
